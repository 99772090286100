import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import mockServer from '../../mockserver/server';

if (environment.production) {
  enableProdMode();
}
// } else {
//   mockServer.start();
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
